import ready from "../supports/ready";

export default async function() {
    await ready();

    const searchWord = "fokusplus";
    const regex = new RegExp("(fokus)(plus)", "gi");

    // Execute XPath 
    const results = document.evaluate(
        `//*[contains(text(), "${searchWord}")]`,
        document,
        null,
        XPathResult.ANY_TYPE,
        null
    );

    // Iterate results - lazy execution to keep DOM / XPathResults object valid
    let node = results.iterateNext();
    let exec = [];
    do {
        if (node instanceof HTMLElement) {
            exec.push((
                (node) => node.innerHTML = node.textContent.replace(regex, `$1<sup data-replace>$2</sup>`)
            ).bind(null, node));
        }
    } while (node = results.iterateNext());
    exec.forEach(cb => cb());
}
