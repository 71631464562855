import ready from "../supports/ready";
import KeenSlider from 'keen-slider';

export default async function () {
    await ready();

    const slider = document.querySelectorAll('[data-keen-slider]');
    [].map.call(slider, function (element) {
        const plugins = [];

        // AutoPlay Function
        let autoplay = parseInt(element.dataset.keenAutoplay || '0');
        if (!isNaN(autoplay) && autoplay > 0) {
            plugins.push(
                (slider) => {
                    let timeout;
                    let mouseOver = false;

                    function clearNextTimeout() {
                        clearTimeout(timeout);
                    }
                    function nextTimeout() {
                        clearTimeout(timeout);
                        if (mouseOver) {
                            return;
                        }
                        timeout = setTimeout(() => {
                            slider.next();
                        }, autoplay);
                    }
                    slider.on("created", () => {
                        slider.container.addEventListener("mouseover", () => {
                            mouseOver = true;
                            clearNextTimeout();
                        });
                        slider.container.addEventListener("mouseout", () => {
                            mouseOver = false;
                            nextTimeout();
                        });
                        nextTimeout();
                    });
                    slider.on("dragStarted", clearNextTimeout);
                    slider.on("animationEnded", nextTimeout);
                    slider.on("updated", nextTimeout);
            });
        }

        // KeenPlay Options
        const options = {
            drag: element.dataset.keenDrag === '1',
            loop: element.dataset.keenLoop === '1',
        };

        // Fade Animation
        if (element.dataset.keenTransition === 'fade') {
            options.loop = true;
            options.defaultAnimation = {
                duration: 2000
            };
            options.detailsChanged = (s) => {
                s.slides.forEach((element, idx) => {
                    element.style.opacity = s.track.details.slides[idx].portion;
                });
            };
            options.renderMode = "custom";
        }

        // Zoom Animation
        if (element.dataset.keenTransition === 'zoom') {
            options.loop = true;
            options.detailsChanged = (s) => {
                const slides = s.track.details.slides
                s.slides.forEach((element, idx) => {
                    var scale_size = 0.7
                    var scale = 1 - (scale_size - scale_size * slides[idx].portion)
                    var style = `scale(${scale})`
                    element.querySelector("div").style.transform = style
                    element.querySelector("div").style["-webkit-transform"] = style
                });
            };
        }

        // Marquee Style
        if (element.dataset.keenStyle === 'marquee') {
            let animation = {
                duration: parseInt(element.dataset.keenAutoplay), 
                easing: (t) => t 
            };
            let amount = parseInt(element.dataset.keenAmount || '1');
            let slides = element.querySelectorAll('.keen-slider__slide').length;
            options.loop = true;
            options.renderMode = "performance";

            options.defaultAnimation = animation;
            options.created = (slider) => {
                slider.moveToIdx(slides, true, animation);
            };
            options.updated = (slider) => {
                slider.moveToIdx(slider.track.details.abs + slides, true, animation);
            };
            options.animationEnded = (slider) => {
                slider.moveToIdx(0, true, { duration: 0, easing: (t) => t });
                slider.moveToIdx(slides, true, animation);
            };
            plugins.shift();
            plugins.push((slider) => {
                slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                        mouseOver = true;
                        slider.animator.stop();
                    });
                    slider.container.addEventListener("mouseout", () => {
                        mouseOver = false;
                        slider.moveToIdx(slider.track.details.abs + slides, true, animation);
                    });
                });
            });
        }

        // Amount of Slides Option
        let amount = parseInt(element.dataset.keenAmount || '1');
        if (!isNaN(amount) && amount > 1) {
            options.slides = {
                perView: 1,
                spacing: 15,
                origin: "center"
            };
            options.breakpoints = {
                '(min-width: 768px)': {
                    slides: {
                        perView: Math.ceil(amount / 3),
                        spacing: 20,
                    }
                },
                '(min-width: 1024px)': {
                    slides: {
                        perView: Math.ceil(amount / 2),
                        spacing: 30,
                    }
                },
                '(min-width: 1200px)': {
                    slides: {
                        perView: amount,
                        spacing: 50,
                    }
                }
            }
        }

        // Keen Slider Centered
        if (element.dataset.keenCentered === '1') {
            options.mode = "free-snap";
            options.slides = {
                perView: 1.5,
                spacing: 15,
            };
            options.breakpoints = {
                ['(min-width: 576px)']: {
                    slides: {
                        perView: 1,
                        spacing: 15,
                    }
                },
                ['(min-width: 768px)']: {
                    slides: {
                        perView: 2,
                        spacing: 20
                    }
                },
                ['(min-width: 992px)']: {
                    slides: {
                        perView: 3,
                        spacing: 30
                    }
                },
                ['(min-width: 1200px)']: {
                    slides: {
                        perView: 3,
                        spacing: 35
                    }
                },
                ['(min-width: 1400px)']: {
                    slides: {
                        perView: 4,
                        spacing: 35
                    }
                },
                ['(min-width: 1900px)']: {
                    slides: {
                        perView: 5,
                        spacing: 35
                    }
                },
                ['(min-width: 2200px)']: {
                    slides: {
                        perView: 6,
                        spacing: 35
                    }
                }
            };

            let container = element.previousElementSibling;
            let padding = document.body.scrollWidth - container.offsetWidth + parseInt(window.getComputedStyle(container).paddingLeft, 10);
            if (padding > 0) {
                element.style.paddingLeft = `${padding / 2}px`;
                element.style.paddingRight = `${padding / 2}px`;
                let clone = element.children[element.children.length-1].cloneNode(true); 
                clone.classList.add('invisible');
                element.append(clone);
            }
        }

        // Initialize Keen Slider
        let keenSlider = new KeenSlider(element, {...options}, [...plugins]);

        // Special Controls
        if (element.dataset.keenCentered === '1') {
            function controlSlider(slider, direction) {
                if (this.disabled || this.classList.contains('disabled')) {
                    return;
                }

                if (direction === 'next') {
                    slider.next();
                } else {
                    slider.prev();
                }
            }

            const controls = element.previousElementSibling.querySelectorAll('[data-keen-controls] .arrowbutton');
            if (controls.length === 2) {
                controls[0].addEventListener('click', controlSlider.bind(controls[0], keenSlider, 'prev'));
                controls[1].addEventListener('click', controlSlider.bind(controls[1], keenSlider, 'next'));
            }

            keenSlider.on('created', (s) => {
                if ((s.options.slides.spacing || 30) >= 35) {
                    if (controls[0].tagName.toUpperCase() === 'BUTTON') {
                        controls[0].disabled = true;
                    } else {
                        controls[0].classList.add('disabled');
                    }
                }
            });
            keenSlider.on('slideChanged', (s) => {
                if ((s.options.slides.spacing || 30) >= 35) {
                    if (controls[0].tagName.toUpperCase() === 'BUTTON') {
                        controls[0].disabled = !(s.track.details.abs > 0);
                        controls[1].disabled = !(s.track.details.abs < s.track.details.slides.length-4);
                    } else {
                        controls[0].classList[s.track.details.abs > 0 ? 'remove' : 'add']('disabled');
                        controls[1].classList[s.track.details.abs < s.track.details.slides.length-4 ? 'remove' : 'add']('disabled');
                    }
                }
            });
        }

        // Bind Control Elements
        if (element.querySelector('[data-keen-control]')) {
            [].map.call(element.querySelectorAll('[data-keen-control]'), (control) => {
                control.addEventListener('click', (event) => {
                    event.preventDefault();
                    if (control.dataset.keenControl === 'next') {
                        keenSlider.next();
                    } else if (control.dataset.keenControl === 'prev') {
                        keenSlider.prev();
                    } else if(!isNaN(parseInt(control.dataset.keenControl))) {
                        let index = parseInt(control.dataset.keenControl);
                        keenSlider.moveToIdx(index-1);
                    }
                });
            });
        }

        // Update Control Element Classes
        function updateClasses() {
            let slide = keenSlider.track.details.rel;
            [].map.call(element.querySelectorAll('[data-keen-control]'), (control) => {
                let index = parseInt(control.dataset.keenControl);
                if (isNaN(index)) {
                    return;
                }
                if (index-1 === slide) {
                    control.classList.add('active');
                } else {
                    control.classList.remove('active');
                }
            });
        }

        // Set Event Listeners
        keenSlider.on("created", updateClasses);
        keenSlider.on("optionsChanged", updateClasses);
        keenSlider.on("slideChanged", updateClasses);
    });
};
