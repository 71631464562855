
import '../scss/main/theme.scss';

import invokeBootstrap from "./plugins/bootstrap";
import invokeKeenSlider from "./plugins/_keen-slider.js";
import invokeLightbox from "./plugins/lightbox";
///@ts-ignore
import invokeNavbar from "./plugins/navbar";
import invokeScrollTop from "./plugins/scroll-top";
import invokeWordFilter from "./plugins/wordfilter";

// Invoke NFCore Plugins
invokeBootstrap();
invokeKeenSlider();
invokeLightbox();
invokeNavbar();
invokeScrollTop();
invokeWordFilter();
